<template>
  <base-section id="aptamers">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="APTAMERS"
            space="0"
          />

          <base-title
            class="primary--text"
            title="NUCLEIC ACIDS AT YOUR SERVICE"
            tag="div"
          />

          <base-body>
            Aptamers are small oligonucleotide molecules that bind to a specific target molecule. The nucleic acid sequence of the aptamers leads to distinct three dimensional structure and thereby defines their biological properties. By altering the nucleic acid sequence of the aptamers we are able to handcraft aptamers for your specific use case.
          </base-body>

          <base-btn
            class="mb-12"
            @click="$vuetify.goTo('#about-our-product')"
          >
            Discover More
          </base-btn>

          <v-row>
            <v-col
              v-for="(text, i) in attributes"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAptamers',

    data: () => ({
      cards: [
        {
          title: 'Nucleic Acids',
          icon: 'far fa-dna',
        },
        {
          title: 'Developed for you',
          color: 'primary',
          dark: true,
          icon: 'far fa-vial',
        },
        {
          title: 'Scalable Production',
          icon: 'far fa-industry-alt',
        },
      ],
      attributes: [
        'Adaptable',
        'Affordable',
        'Robust',
        'Versatile',
      ],
    }),
  }
</script>
